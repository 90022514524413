<template>
	<Component
		:is="tagName"
		:href="href"
		:target="target"
		:rel="rel"
		:title="alt"
		:style="imageCSSVars"
		class="image"
		:class="{
			'image--grid': !isUnstyled,
			'image--unstyled': isUnstyled,
			'image--link': tagName === ANCHOR_TAG,
		}"
		@click="$emit('image-click', $event)"
	>
		<img
			v-qa="'builder-gridelement-gridimage'"
			:alt="alt"
			:src="src"
			:srcset="srcset"
			:sizes="sizes"
			:height="height"
			:width="width"
			:loading="isEager? 'eager' : 'lazy'"
			:class="{
				'image__image--zoom': isLightboxEnabled,
				'image__image--unstyled': isUnstyled,
				'image__image--cropped': !!cropCssVars,
				'image__image': !isUnstyled,
				'image__image--reset-m-position': resetMobilePosition
			}"
			@load="$emit('image-load')"
			v-on="{
				drag: preventDrag ? (e) => e.preventDefault() : () => null,
				dragstart: preventDrag ? (e) => e.preventDefault() : () => null,
			}"
		>
		<slot />
	</Component>
</template>

<script>
import {
	ANCHOR_TAG,
	DIV_TAG,
} from '@zyro-inc/site-modules/constants';

import {
	computed,
	defineComponent,
} from 'vue';

const ALLOWED_TAG_NAMES = [
	DIV_TAG,
	ANCHOR_TAG,
];

export default defineComponent({
	props: {
		alt: {
			type: String,
			default: null,
		},
		href: {
			type: String,
			default: null,
		},
		preventDrag: {
			type: Boolean,
			default: false,
		},
		sizes: {
			type: String,
			default: null,
		},
		src: {
			type: String,
			required: true,
		},
		srcset: {
			type: String,
			default: null,
		},
		tagName: {
			type: String,
			validator: (tag) => ALLOWED_TAG_NAMES.includes(tag),
			default: 'div',
		},
		target: {
			type: String,
			default: null,
		},
		rel: {
			type: String,
			default: null,
		},
		isLightboxEnabled: {
			type: Boolean,
			default: false,
		},
		isUnstyled: {
			type: Boolean,
			default: false,
		},
		resetMobilePosition: {
			type: Boolean,
			default: true,
		},
		width: {
			type: Number,
			default: null,
		},
		height: {
			type: Number,
			default: null,
		},
		cropCssVars: {
			type: Object,
			default: null,
		},
		isOverflowVisible: {
			type: Boolean,
			default: false,
		},
		isEager: {
			type: Boolean,
			default: false,
		},
	},

	setup(props) {
		return {
			ANCHOR_TAG,
			imageCSSVars: computed(() => ({
				'--overflow': props.isOverflowVisible ? 'visible' : null,
				...props.cropCssVars,
			})),
		};
	},
});
</script>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.image {
	overflow: var(--overflow, hidden);

	&--grid {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
	}

	&--unstyled {
		// Fill element with image
		display: flex;
	}

	&--link {
		transition: filter 0.2s ease;

		&:hover {
			filter: contrast(0.8);
		}
	}

	&__image {
		// <img /> size is controlled by parent element (which is controlled by grid)
		position: absolute;
		display: block;
		object-fit: var(--object-fit, cover);
		border-radius: var(--border-radius);

		&--zoom {
			cursor: zoom-in;
		}

		// When size is not controlled by grid
		&,
		&--unstyled {
			width: 100%;
			height: 100%;
		}

		&--cropped {
			top: var(--desktop-top);
			left: var(--desktop-left);
			width: var(--desktop-width);
			height: var(--desktop-height);
			transform: scale(var(--desktop-scale));
			transform-origin: 0 0;
		}
	}
}

@include site-engine-mobile {
	.image {
		&__image {
			&--cropped {
				top: var(--mobile-top);
				left: var(--mobile-left);
				width: var(--mobile-width);
				height: var(--mobile-height);
				transform: scale(var(--mobile-scale));
			}

			&--reset-m-position {
				position: static;
				width: 100%;
				height: auto;
			}
		}
	}
}
</style>
