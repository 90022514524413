import { filterHiddenPages } from '@zyro-inc/site-modules/utils/filterHiddenPages';
import { isInPreviewIframe } from '@zyro-inc/site-modules/utils/isInPreviewIframe';

export const fetchSiteData = async () => {
	// don't do initial fetch in preview mode
	if (isInPreviewIframe) {
		return null;
	}

	const currentDate = new Date().setHours(0, 0, 0, 0);

	if (process.env.NODE_ENV === 'development') {
		const playgroundModules = import.meta.glob('@zyro-inc/site-modules/templates/playground.json', {
			as: 'raw',
		});
		const [rawPlaygroundJson] = Object.values(playgroundModules);
		const playgroundSiteData = JSON.parse(rawPlaygroundJson);

		return {
			...playgroundSiteData,
			languages: filterHiddenPages(playgroundSiteData.languages, currentDate),
		};
	}

	const response = await fetch(`${window.location.origin}/data.json`);
	const siteData = await response.json();

	return {
		...siteData,
		languages: filterHiddenPages(siteData.languages, currentDate),
	};
};
