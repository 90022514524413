<!-- This is a linted copy of: https://gitlab.com/broj42/vue-customizable-datepicker/-/blob/master/src/DatePicker.vue -->
<!-- TODO: this is needed only as a temporary solution to help Vue 3 migration and should be replaced -->
<template>
	<div
		class="calendar"
		:class="{
			'isFillRangeEnabled': fillRange,
			'isAllwaysOpened': opened,
			'isOpened': isCalendarOpened
		}"
	>
		<div
			v-if="!opened"
			class="calendar__Input"
			@click="toggleCalendar"
		>
			<div v-if="range && rangeDates && rangeDates.from && rangeDates.to">
				<slot
					name="value"
					v-bind="{
						from: rangeDates.from,
						to: rangeDates.to
					}"
				>
					{{ formatDate(rangeDates.from) }}
					-
					{{ formatDate(rangeDates.to) }}
				</slot>
			</div>
			<div v-else-if="selectedDate && pickerValue">
				<slot
					name="value"
					v-bind="{ date: selectedDate }"
				>
					{{ formatDate(selectedDate) }}
				</slot>
			</div>
			<span
				v-else
				v-text="placeholder"
			/>
		</div>
		<div
			v-show="isCalendarOpened"
			class="calendar__Items"
		>
			<div
				v-for="calendar in calendars"
				:key="calendar.id"
				class="calendar__Item"
				@click.stop
			>
				<div class="calendar__Header">
					<template v-if="!calendar.isSelectMonthOpened && !calendar.isSelectYearOpened">
						<button
							v-if="arrows"
							class="calendar__Button calendar__Previous"
							@click.prevent.stop="monthArrowClicked(calendar, 'previous')"
						>
							<slot name="arrow-month-previous">
								<span>&lt;</span>
							</slot>
						</button>
						<div
							class="calendar__HeaderDate"
							@click="openSelectors(calendar)"
						>
							<slot
								name="header"
								v-bind="{
									month: calendar.month,
									year: calendar.year
								}"
							>
								<span>{{ calendar.month }} - {{ calendar.year }}</span>
							</slot>
						</div>
						<button
							v-if="arrows"
							class="calendar__Button calendar__Next"
							@click.prevent.stop="monthArrowClicked(calendar, 'next')"
						>
							<slot name="arrow-month-next">
								<span>&gt;</span>
							</slot>
						</button>
					</template>
					<template v-else>
						<div
							v-if="calendar.isSelectMonthOpened"
							class="calendar__Months"
						>
							<ul>
								<li
									v-for="m in 12"
									:key="m"
									:class="{ 'isSelected': calendar.month === (zeroPad ? zeroPadNumber(m) : m) }"
									@click.stop="setCalendarMonth(calendar, m)"
									v-text="zeroPad ? zeroPadNumber(m) : m"
								/>
							</ul>
						</div>
						<div
							v-else-if="calendar.isSelectYearOpened"
							class="calendar__Years"
							@click.stop
						>
							<button
								class="calendar__Button calendar__Previous"
								@click.prevent.stop="generateYears('previous')"
							>
								<slot name="arrow-year-previous">
									<span>&lt;</span>
								</slot>
							</button>
							<button
								class="calendar__Button calendar__Next"
								@click.prevent.stop="generateYears('next')"
							>
								<slot name="arrow-year-next">
									<span>></span>
								</slot>
							</button>
							<ul>
								<li
									v-for="y in years"
									:key="y"
									:class="{ 'isSelected': calendar.year === y }"
									@click.stop="setCalendarYear(calendar, y)"
									v-text="y"
								/>
							</ul>
						</div>
					</template>
				</div>
				<div class="calendar">
					<span class="calendar__DayNames">
						<span
							v-for="d in 7"
							:key="d"
							class="calendar__DayName"
							v-text="dayNamesMerged[d-1]"
						/>
					</span>
					<span
						v-for="week in calendar.weeks"
						:key="week.id"
						class="calendar__Week"
					>
						<span
							v-for="day in week"
							:key="day.id"
							class="calendar__Day"
							:class="{
								'isSelected': compareDates(day, selectedDate),
								'isToday': compareDates(day, now),
								'isInRange': isInRange(day),
								'isDisabled': isDisabled(day),
								'isDifferentMonth': isDifferentMonth(day, calendar.weeks[0]),
								'isFirstInRange' :range && compareDates(day, rangeDates.from),
								'isLastInRange' : range && compareDates(day, rangeDates.to)
							}"
							@click="selectDate(day)"
						>
							<template v-if="day !== 0">
								<slot
									name="day"
									v-bind="{ day: zeroPadDays ? zeroPadNumber(day.getDate()) : day.getDate() }"
								>
									{{ zeroPadDays ? zeroPadNumber(day.getDate()) : day.getDate() }}
								</slot>
							</template>
						</span>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/* eslint-disable no-param-reassign */
const now = new Date();

now.setHours(0, 0, 0, 0);

const getDatesRange = (n, year, month) => [...Array(n).keys()].map((i) => new Date(year, month - 1, i + 1));

const getWeekChunks = (year, month, fillEmpty, firstDayNumber) => {
	const numberOfDays = new Date(year, month, 0).getDate();
	const range = getDatesRange(numberOfDays, year, month);
	let firstDay = new Date(year, month - 1, 1).getDay();

	firstDay = firstDay === 0 ? 7 : firstDay;

	let numberOfPadding = 7 - firstDayNumber + firstDay;

	numberOfPadding %= 7;

	for (let i = 0; i < numberOfPadding; i += 1) {
		if (fillEmpty) range.unshift(new Date(year, month - 1, -i));
		else range.unshift(0);
	}

	const chunks = getDatesRange(
		Math.ceil(range.length / 7),
		year,
		month,
	).map((x, i) => range.slice(i * 7, i * 7 + 7));

	if (fillEmpty) {
		const emptyPlaces = chunks[chunks.length - 1].length;

		for (let i = 0; i < 7 - emptyPlaces; i += 1) {
			chunks[chunks.length - 1].push(new Date(year, month, i + 1));
		}
	}

	return chunks;
};

export default {
	name: 'DatePicker',
	props: {
		value: {
			type: Date,
			default: null,
		},
		range: {
			type: Boolean,
			default: false,
		},
		exactRangeDates: {
			type: Boolean,
			default: false,
		},
		numberOfCalendars: {
			type: Number,
			default: 1,
		},
		firstDay: {
			type: Number,
			default: 1,
			validator: (value) => {
				if (value > 6 || value < 0) throw new Error('Property firstDay should be a Number between 0 and 6.');

				return value <= 6 && value >= 0;
			},
		},
		disabledDates: {
			type: Array,
			default: () => [],
		},
		format: {
			type: String,
			default: '%d.%m.%Y',
		},
		dayNames: {
			type: [
				Array,
				Object,
			],
			default: () => ({
				1: 'Mo',
				2: 'Tu',
				3: 'We',
				4: 'Th',
				5: 'Fr',
				6: 'Sa',
				0: 'Su',
			}),
			validator: (value) => {
				const hasAllKeys = [...Array(7).keys()].every((i) => value[i]);

				if (!hasAllKeys) throw new Error('Property dayNames should be an Object with this properties: 0, 1, 2, 3, 4, 5, 6.');

				return hasAllKeys;
			},
		},
		fillEmpty: {
			type: Boolean,
			default: true,
		},
		fillRange: {
			type: Boolean,
			default: false,
		},
		opened: {
			type: Boolean,
			default: false,
		},
		closeOnClick: {
			type: Boolean,
			default: true,
		},
		zeroPad: {
			type: Boolean,
			default: true,
		},
		zeroPadDays: {
			type: Boolean,
			default: false,
		},
		arrows: {
			type: Boolean,
			default: true,
		},
		placeholder: {
			type: String,
			default: 'Pick a date',
		},
		disableBefore: {
			type: Date,
			default: null,
		},
		disableAfter: {
			type: Date,
			default: null,
		},
	},

	data() {
		return {
			now,
			year: now.getFullYear(),
			month: now.getMonth() + 1,
			calendars: [],
			pickerValue: this.value,
			years: [...Array(9).keys()].map((i) => i + now.getFullYear()),
			selectedDate:
        this.value && !this.value.from && !Array.isArray(this.value) ? this.value : undefined,
			formatedDate: undefined,
			clickedDates: [],
			isCalendarOpened: this.opened,
			rangeDates: {
				from:
          !Array.isArray(this.value)
          && this.value
          && !this.exactRangeDates
          && this.value.from ? this.value.from : undefined,
				to:
          !Array.isArray(this.value)
          && this.value
          && !this.exactRangeDates
          && this.value.to ? this.value.to : undefined,
			},
		};
	},

	computed: {
		dayNamesMerged() {
			return [...Array(7).keys()].map((i) => this.dayNames[(this.firstDay + i) % 7]);
		},
	},

	watch: {
		value: {
			handler(value) {
				this.pickerValue = value;
				if (value) {
					if (!value.from) this.selectedDate = value;
					else {
						this.rangeDates.from = !this.exactRangeDates && value.from ? value.from : undefined;
						this.rangeDates.to = !this.exactRangeDates && value.to ? value.to : undefined;
						this.selectedDate = undefined;
					}
				}

				this.$emit('input', value);
			},
			deep: true,
		},
	},

	created() {
		this.setCalendar(undefined, true);
	},

	methods: {
		getWeekChunks(year, month) {
			return getWeekChunks(year, month, this.fillEmpty, this.firstDay);
		},

		generateYears(direction) {
			const first = this.years[0] - 9 * (direction === 'next' ? -1 : 1);

			this.years = [...Array(9).keys()].map((i) => i + first);
		},

		monthArrowClicked(calendar, direction) {
			const temp = new Date(this.findFirstDay(calendar.weeks[0]).getTime());

			temp.setMonth(temp.getMonth() + (direction === 'next' ? 1 : -1));

			this.setCalendarMonth(calendar, temp.getMonth() + 1, false);
			this.setCalendarYear(calendar, temp.getFullYear(), false);
			this.setCalendar(calendar, false);
			this.$emit('month-change', {
				month: temp.getMonth() + 1,
				year: temp.getFullYear(),
			});
		},

		setCalendarMonth(calendar, month, fromPicker = true) {
			calendar.month = this.zeroPad ? this.zeroPadNumber(month) : month;
			if (fromPicker) this.setCalendar(calendar, false, 'isSelectMonthOpened');
		},

		setCalendarYear(calendar, year, fromPicker = true) {
			if (calendar.year !== year) {
				this.$emit('year-change', {
					month: Number.parseInt(calendar.month, 10),
					year,
				});
			}

			calendar.year = year;
			if (fromPicker) {
				this.setCalendar(calendar, false, 'isSelectYearOpened');
				this.years = [...Array(9).keys()].map((i) => i + now.getFullYear());
			}
		},

		setCalendar(calendar, isInitialization = false, selector) {
			if (isInitialization) {
				let startDate;

				if (this.pickerValue) {
					if (this.pickerValue.from) startDate = new Date(this.pickerValue.from.getTime());
					else if (Array.isArray(this.pickerValue) && Array.isArray.length > 0) {
						startDate = new Date(this.pickerValue[0].getTime());
					} else startDate = new Date(this.pickerValue.getTime());
				} else startDate = new Date(this.now.getTime());

				for (let i = 0; i < this.numberOfCalendars; i += 1) {
					const year = startDate.getFullYear();
					let month = startDate.getMonth() + 1;

					if (this.zeroPad) month = this.zeroPadNumber(month);

					this.calendars.push({
						year,
						month,
						weeks: getWeekChunks(year, month, this.fillEmpty, this.firstDay),
						isSelectMonthOpened: false,
						isSelectYearOpened: false,
					});
					startDate.setMonth(startDate.getMonth() + 1);
				}
			} else {
				if (selector === 'isSelectMonthOpened') calendar.isSelectYearOpened = true;
				if (selector) calendar[selector] = false;
				calendar.weeks = getWeekChunks(
					calendar.year,
					calendar.month,
					this.fillEmpty,
					this.firstDay,
				);
			}
		},

		selectDate(date) {
			if (date !== 0) {
				this.selectedDate = date;
				if (this.range) {
					if (this.clickedDates.length === 0) {
						this.rangeDates.from = undefined;
						this.rangeDates.to = undefined;
						if (this.exactRangeDates) this.pickerValue = [];
					}

					this.clickedDates.push(date);
					if (this.clickedDates.length === 2) {
						this.clickedDates.sort((a, b) => a - b);
						[this.rangeDates.from, this.rangeDates.tp] = this.clickedDates;
						this.clickedDates = [];
						this.selectedDate = undefined;
						let pickerValue = [];

						if (!this.exactRangeDates) {
							pickerValue = {
								from: this.rangeDates.from,
								to: this.rangeDates.to,
							};
						} else {
							for (
								let d = new Date(this.rangeDates.from.getTime());
								d.getTime() <= this.rangeDates.to.getTime();
								d.setDate(d.getDate() + 1)
							) {
								if (this.isInRange(d)) pickerValue.push(new Date(d.getTime()));
							}
						}

						this.pickerValue = pickerValue;
						if (this.closeOnClick) this.closeCalendar();
					}
				} else {
					if (this.closeOnClick) this.closeCalendar();
					this.pickerValue = this.selectedDate;
				}
			}

			this.$emit('input', this.pickerValue);
		},

		zeroPadNumber(number) {
			return number.toString().padStart(2, '0');
		},

		formatDate(date) {
			if (!date) return null;
			let day = date.getDate();
			let month = date.getMonth() + 1;
			const year = date.getFullYear();

			if (this.zeroPad) {
				day = this.zeroPadNumber(day);
				month = this.zeroPadNumber(month);
			}

			const returnDate = this.format
				.replace(/%d/g, day)
				.replace(/%m/g, month)
				.replace(/%Y/g, year)
				.replace(/%y/g, year % 100);

			return returnDate;
		},

		openSelectors(calendar) {
			calendar.isSelectMonthOpened = true;
		},

		toggleCalendar() {
			this.isCalendarOpened = !this.isCalendarOpened;
		},

		closeCalendar() {
			if (!this.opened) this.isCalendarOpened = false;
		},

		findFirstDay(week) {
			return week.find((d) => d.getDate() === 1);
		},

		compareDates(a, b) {
			if (a && b) return this.setAllToZero(a).getTime() === this.setAllToZero(b).getTime();

			return false;
		},

		setAllToZero(date) {
			date.setHours(0);
			date.setMinutes(0);
			date.setSeconds(0);
			date.setMilliseconds(0);

			return date;
		},

		isBetween(date, a, b) {
			if (date && a && b) return date.getTime() >= a && date.getTime() <= b;

			return false;
		},

		isBefore(a, b) {
			return this.setAllToZero(a) < this.setAllToZero(b);
		},

		isAfter(a, b) {
			return this.setAllToZero(a) > this.setAllToZero(b);
		},

		isDisabled(date) {
			for (let i = 0; i < this.disabledDates.length; i += 1) {
				if (Array.isArray(this.disabledDates[i])) {
					const d = [...this.disabledDates[i]];

					d.sort((a, b) => a - b);
					if (this.isBetween(date, d[0], d[1])) return true;
				} else if (this.compareDates(date, this.disabledDates[i])) return true;
			}

			if (this.disableBefore && this.isBefore(date, this.disableBefore)) return true;
			if (this.disableAfter && this.isAfter(date, this.disableAfter)) return true;

			return false;
		},

		isInExactRange(date) {
			if (this.exactRangeDates && Array.isArray(this.pickerValue)) {
				return this.pickerValue.some((d) => this.compareDates(d, date));
			}

			return false;
		},

		isInRange(date) {
			if (this.range) {
				return (
					!this.isDisabled(date)
          && (this.isBetween(date, this.rangeDates.from, this.rangeDates.to)
            || this.isInExactRange(date))
				);
			}

			return false;
		},

		isDifferentMonth(date, week) {
			if (this.fillEmpty) return date.getMonth() !== this.findFirstDay(week).getMonth();

			return false;
		},
	},
};
</script>
<style>
/* stylelint-disable */
.calendar {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.calendar * {
	box-sizing: border-box;
}

.calendar.isOpened {
	z-index: 1000;
}

.calendar.isFillRangeEnabled .calendar__Day {
	position: relative;
}

.calendar.isFillRangeEnabled .calendar__Day.isInRange::before {
	position: absolute;
	top: 0;
	right: -2px;
	bottom: 0;
	left: -2px;
	z-index: -1;
	content: "";
	background-color: inherit;
}

.calendar.isFillRangeEnabled .calendar__Day.isInRange.isFirstInRange::before {
	left: 0;
	border-top-left-radius: 50%;
	border-bottom-left-radius: 50%;
}

.calendar.isFillRangeEnabled .calendar__Day.isInRange.isLastInRange::before {
	right: 0;
	border-top-right-radius: 50%;
	border-bottom-right-radius: 50%;
}

.calendar.isAllwaysOpened .calendar__Items {
	position: relative;
}

.calendar.isAllwaysOpened .calendar__Input {
	cursor: default;
}

.calendar__Week,
.calendar__DayNames {
	display: flex;
}

.calendar__Day,
.calendar__DayName {
	min-width: 28px;
	height: 28px;
	margin: 2px;
	font-size: 14px;
	text-align: center;
}

.calendar__DayName {
	font-weight: bold;
}

.calendar__Day {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 50%;
}

.calendar__Day:hover:not(.isSelected):not(.isInRange) {
	color: #000;
	background-color: #eee;
	transition: background-color 200ms, color 200ms;
}

.calendar__Day.isToday {
	color: #fff;
	background-color: #aaa;
}

.calendar__Day.isSelected,
.calendar__Day.isInRange {
	color: #fff;
	background-color: #333;
}

.calendar__Day.isDisabled {
	pointer-events: none;
	opacity: 0.5;
}

.calendar__Day.isDifferentMonth {
	opacity: 0.25;
}

.calendar__Input {
	cursor: pointer;
}

.calendar__Header {
	display: flex;
	justify-content: space-between;
	padding-bottom: 20px;
	font-size: 16px;
	text-align: center;
}

.calendar__HeaderDate {
	margin: 0 auto;
	cursor: pointer;
}

.calendar__Items {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	padding: 20px 0 0;
	background-color: #fff;
	border: 1px solid #333;
}

.calendar__Item {
	position: relative;
	margin: 0 20px 20px;
}

.calendar__Months,
.calendar__Years {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	background-color: #111;
}

.calendar__Months ul,
.calendar__Years ul {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.calendar__Months li,
.calendar__Years li {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25%;
	color: #fff;
	cursor: pointer;
	transition: background-color 200ms;
}

.calendar__Months li:hover,
.calendar__Years li:hover {
	background-color: #555;
}

.calendar__Months li.isSelected,
.calendar__Years li.isSelected {
	background-color: #333;
}

.calendar__Months li {
	width: 33.333%;
	height: 25%;
}

.calendar__Years {
	align-items: flex-end;
	padding-top: 40px;
}

.calendar__Years li {
	width: 33.333%;
	height: 33.333%;
}

.calendar__Years button {
	position: absolute;
	top: 10px;
}

.calendar__Years button:first-of-type {
	left: 10px;
}

.calendar__Years button:last-of-type {
	right: 10px;
}

.calendar__Years button:hover {
	opacity: 0.5;
}

.calendar__Years button span {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 129 129' enable-background='new 0 0 129 129' width='512px' height='512px'%3E%3Cg%3E%3Cpath xmlns='http://www.w3.org/2000/svg' fill='%23fff' d='m88.6,121.3c0.8,0.8 1.8,1.2 2.9,1.2s2.1-0.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-51-51 51-51c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-54,53.9c-1.6 1.6-1.6,4.2 0,5.8l54,53.9z'/%3E%3C/g%3E%3C/svg%3E");
}

.calendar__Button {
	position: relative;
	width: 20px;
	height: 20px;
	padding: 5px;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	outline: 0;
	transition: opacity 200ms;
}

.calendar__Button:hover {
	opacity: 0.5;
}

.calendar__Button span {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	font-size: 0;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 129 129' enable-background='new 0 0 129 129' width='512px' height='512px'%3E%3Cg%3E%3Cpath xmlns='http://www.w3.org/2000/svg' fill='%23000' d='m88.6,121.3c0.8,0.8 1.8,1.2 2.9,1.2s2.1-0.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-51-51 51-51c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-54,53.9c-1.6 1.6-1.6,4.2 0,5.8l54,53.9z'/%3E%3C/g%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: cover;
}

.calendar__Next span {
	transform: rotate(180deg);
}
</style>
