import {
	getCurrentInstance,
	computed,
} from 'vue';

export const useEcommerceGridButton = (props) => {
	const { state } = getCurrentInstance().proxy.$store;

	const storeProducts = computed(() => state.ecommerce?.products);
	const productId = computed(() => props.data.settings?.productId);
	const productVariantId = computed(() => props.data.settings?.productVariantId);

	return {
		storeProducts,
		productId,
		productVariantId,
	};
};
