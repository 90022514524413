import {
	getCurrentInstance,
	computed,
} from 'vue';
import { isAppPrerendering } from '@zyro-inc/site-modules/utils/prerenderingFlags';
import { productsPerPageByColumnCount } from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';

export const useBlockEcommerceProductList = (props) => {
	const {
		state,
		getters,
	} = getCurrentInstance().proxy.$store;

	const isLoading = computed(() => isAppPrerendering || state.ecommerce?.isLoading);
	const productPages = computed(() => getters.ecommerceProductPages);

	// TODO ecommerce: remove check after https://github.com/zyro-inc/zyro/issues/13470 release
	const productList = computed(() => {
		let products;

		if (getters['ecommerce/isStoreTypeZyro']) {
			products = state.ecommerce?.products.filter((product) => Object.values(productPages.value).find(
				(page) => page.productId === product.id,
			));
		} else {
			products = state.ecommerce?.products;
		}

		if (props.data.productCategoryId) {
			products = products.filter((product) => product.product_collections.some(
				(category) => category.collection_id === props.data.productCategoryId,
			));

			return products?.sort((a, b) => {
				const first = a.product_collections.find((item) => item.collection_id === props.data.productCategoryId);
				const second = b.product_collections.find((item) => item.collection_id === props.data.productCategoryId);

				return first.order - second.order;
			});
		}

		return products?.sort((a, b) => a.title.localeCompare(b.title));
	});
	const blockStyle = computed(() => props.data.settings?.styles);
	const textColorVars = computed(() => props.data.textColorVars);
	const isPaginationEnabled = computed(() => props.data.isPaginationEnabled);
	const columnCount = computed(() => props.data.columnCount);
	const productsPerPage = computed(() => props.data.productsPerPage || productsPerPageByColumnCount[columnCount.value]);

	return {
		isLoading,
		productList,
		productPages,
		textColorVars,
		blockStyle,
		isPaginationEnabled,
		columnCount,
		productsPerPage,
	};
};
