import Vue from 'vue';
import Vuex from 'vuex';

import {
	SYSTEM_LOCALE,
	PAGE_TYPE_BLOG,
	PAGE_TYPE_ECOMMERCE_PRODUCT,
} from '@zyro-inc/site-modules/constants';
import { filterObject } from '@zyro-inc/site-modules/utils/object';
import { getEcwidPages } from '@zyro-inc/site-modules/utils/getters/getEcwidPages';
import { getPathParams } from '@zyro-inc/site-modules/utils/page/getPathParams';

import ecommerce from './ecommerce';

Vue.use(Vuex);

export const storeConfig = {
	modules: {
		ecommerce,
	},
	state: {
		website: null,
		currentLocale: SYSTEM_LOCALE,
	},
	getters: {
		siteMeta: (state) => state.website?.meta ?? {},
		// this is a mega hack!
		// 'currentLanguageData' should not be needed for all user sites (and all pages) - only at Page.vue level
		// 'currentLocale' should not be stored at state
		// currently it's used only for some getters to calculate some insignificant things
		// getters, which depend on 'currentLanguageData', should be made into computeds or not used at all
		currentLanguageData: (state) => state.website.languages[state.currentLocale] ?? {},
		siteLanguages: (state) => state.website?.languages ?? {},
		hasLanguages: (state, getters) => Object.keys(getters.siteLanguages).length > 1, // we always have system language
		homePageId: (state, getters) => getters.currentLanguageData?.homePageId,
		defaultLocale: (state, getters) => getters.siteMeta.defaultLocale ?? SYSTEM_LOCALE,
		blogPages: (state, getters) => filterObject(getters.currentLanguageData?.pages, ({ value }) => value.type === PAGE_TYPE_BLOG),
		ecwidPages: (state, getters) => getEcwidPages({
			pages: getters.currentLanguageData.pages,
			blocks: getters.currentLanguageData.blocks,
		}) ?? {},
		ecommerceLocaleProductPages: (state, getters) => filterObject(getters.currentLanguageData?.pages,
			({ value }) => value.type === PAGE_TYPE_ECOMMERCE_PRODUCT),
		// zyro ecommerce pages are not adapted for multi-language so we need to have a getter for all website pages
		ecommerceProductPages: (state, getters) => filterObject(
			getters.currentLanguageData?.pages, ({ value }) => value.type === PAGE_TYPE_ECOMMERCE_PRODUCT,
		),
		isHomepageWithEcwid: (state, getters) => Object.keys(getters.ecwidPages).includes((pageId) => pageId === getters.homePageId),
		publishedBlogPages: (state, getters) => filterObject(getters.blogPages, ({ value }) => !value.isDraft),
	},
	actions: {
		forceCurrentLocale: async ({
			state,
			commit,
		}, path) => {
			const { locale } = getPathParams({
				path,
				defaultLocale: state.website?.meta.defaultLocale ?? SYSTEM_LOCALE,
				languages: state.website?.languages,
			});

			if (locale !== state.currentLocale) {
				commit('setCurrentLocale', locale);
			}
		},
	},
	mutations: {
		setWebsite: (state, { website }) => {
			state.website = website;
		},
		setCurrentLocale: (state, locale) => {
			state.currentLocale = locale;
		},
	},
};
export default new Vuex.Store(storeConfig);
