import {
	ref,
	getCurrentInstance,
	computed,
} from 'vue';

// Use one instance for all buttons
const stripeInstance = ref(null);

// One time payment with checkout docs:
// https://stripe.com/docs/payments/checkout/client

export const useStripeGridButton = (props) => {
	const priceId = computed(() => props.data.settings?.priceId || '');
	const paymentType = computed(() => props.data.settings?.paymentType || 'payment');
	const { pages } = getCurrentInstance().proxy.$store.getters.currentLanguageData;
	const successPageSlug = computed(() => (
		pages[props.data.settings?.successPageId] || Object.values(pages)[0]).slug);
	const cancellationPageSlug = computed(() => (
		pages[props.data.settings?.cancellationPageId] || Object.values(pages)[0]).slug);

	return {
		stripeInstance,
		priceId,
		paymentType,
		successPageSlug,
		cancellationPageSlug,
	};
};
