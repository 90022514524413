import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import Vue from 'vue';
import Router from 'vue-router';

import { QA_DIRECTIVE_NAME } from '@zyro-inc/site-modules/constants';

import { addDocumentElements } from '@/utils/addDocumentElements';

import {
	UPDATE_SITE_DATA,
	NAVIGATE_TO_PATH,
	ADD_DOCUMENT_ELEMENTS,
	RESET_SHOPPING_CART_ITEMS,
} from '@zyro-inc/site-modules/constants/messageEvents';

import '@/assets/scss/global.scss';
import App from '@/App.vue';
import { dataQADirective } from '@/utils/qaAttributeDirective';
import { fetchSiteData } from '@/utils/fetchSiteData';
import router from '@/router';
import store from '@/store';

if ('ResizeObserver' in window === false) {
	window.ResizeObserver = ResizeObserverPolyfill;
}

Vue.use(Router);
Vue.directive(QA_DIRECTIVE_NAME, dataQADirective);
Vue.config.productionTip = false;

const app = new Vue({
	store,
	mounted: () => {
		// 'site-engine-mount' custom event is used in prerender service
		// it notifies lambda that app is mounted and it could save the HTML output
		document.dispatchEvent(new Event('site-engine-mount'));
		// when all external dependencies are loaded, fire 'DOMContentLoaded', because some external scripts depend on it
		window.addEventListener('load', () => document.dispatchEvent(new Event('DOMContentLoaded')));
	},
	router,
	render: (h) => h(App),
});

app.$watch('$route.path', (path) => {
	addDocumentElements({
		siteData: app.$store.state.website,
		path,
	});
});

fetchSiteData().then((siteData) => {
	if (siteData) {
		addDocumentElements({
			siteData,
			path: app.$route.path,
		});
		store.commit('setWebsite', {
			website: siteData,
		});
	}
}).finally(() => {
	app.$mount('#app');
});

/**
 * Listen for message events to allow setting data externally
 * Used for previews
 */
window.addEventListener('message', ({ data }) => {
	if (typeof data !== 'object') {
		return;
	}

	if (data.type === UPDATE_SITE_DATA) {
		store.commit('setWebsite', {
			website: data.payload.siteData,
		});

		if (data.payload.path && data.payload.path !== router.currentRoute.path) {
			router.push({
				path: data.payload.path,
			});
		}
	}

	if (data.type === NAVIGATE_TO_PATH) {
		if (data.payload.path !== router.currentRoute.path) {
			router.push({
				path: data.payload.path,
			});
		}
	}

	if (data.type === ADD_DOCUMENT_ELEMENTS) {
		addDocumentElements({
			siteData: data.payload.siteData,
			path: app.$route.path,
		});
	}

	if (data.type === RESET_SHOPPING_CART_ITEMS) {
		store.dispatch('ecommerce/setShoppingCartItems', []);
	}
});
